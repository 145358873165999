// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/icomoon.eot?u4ydr3", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/icomoon.ttf?u4ydr3", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/icomoon.woff?u4ydr3", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/icomoon.svg?u4ydr3", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
@font-face {
  font-family: "icomoon";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("embedded-opentype"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=header-icon-],
[class*=header-icon-] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 4em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-icon-health-professional:before {
  content: "󩀀";
}

.header-icon-ecec:before {
  content: "󩀁";
}

.header-icon-parents:before {
  content: "󩀂";
}

.header-icon-translate:before {
  content: "󩀃";
}

.header-icon-indigenous:before {
  content: "󩀄";
}

.header-icon-toolbox:before {
  content: "󩀅";
}`, "",{"version":3,"sources":["webpack://./src/assets/icons/header/style.css"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,sBAAA;EACA,4CAAA;EACA,mPACI;EAIJ,mBAAA;EACA,kBAAA;EACA,mBAAA;AAFJ;AAKA;;EAEI,+EAAA;EACA,iCAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,oBAAA;EACA,oBAAA;EACA,cAAA;EACA,cAAA;EAEA,mCAAA;EACA,kCAAA;AAJJ;;AAOA;EACI,aAAA;AAJJ;;AAMA;EACI,aAAA;AAHJ;;AAKA;EACI,aAAA;AAFJ;;AAIA;EACI,aAAA;AADJ;;AAGA;EACI,aAAA;AAAJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":["@font-face {\n    font-family: 'icomoon';\n    src: url('fonts/icomoon.eot?u4ydr3');\n    src:\n        url('fonts/icomoon.eot?u4ydr3#iefix') format('embedded-opentype'),\n        url('fonts/icomoon.ttf?u4ydr3') format('truetype'),\n        url('fonts/icomoon.woff?u4ydr3') format('woff'),\n        url('fonts/icomoon.svg?u4ydr3#icomoon') format('svg');\n    font-weight: normal;\n    font-style: normal;\n    font-display: block;\n}\n\n[class^='header-icon-'],\n[class*='header-icon-'] {\n    /* use !important to prevent issues with browser extensions that change fonts */\n    font-family: 'icomoon' !important;\n    speak: never;\n    font-style: normal;\n    font-weight: normal;\n    font-variant: normal;\n    text-transform: none;\n    line-height: 1;\n    font-size: 4em;\n\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\n.header-icon-health-professional:before {\n    content: '\\e9000';\n}\n.header-icon-ecec:before {\n    content: '\\e9001';\n}\n.header-icon-parents:before {\n    content: '\\e9002';\n}\n.header-icon-translate:before {\n    content: '\\e9003';\n}\n.header-icon-indigenous:before {\n    content: '\\e9004';\n}\n.header-icon-toolbox:before {\n    content: '\\e9005';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
